import { ErrorHandler, inject, Injectable } from '@angular/core';

import { UserService } from '~/api/user/user.service';

import { GoogleAnalyticsService } from './google-analytics.service';

@Injectable()
export class ErrorHandlerService extends ErrorHandler {
  protected gaService = inject(GoogleAnalyticsService);
  protected userService = inject(UserService);

  override handleError(error: Error) {
    console.error(error);
    this.gaService.event('exception', {
      exception_name: error.name,
      exception_message: error.message,
      exception_stack: error.stack,
      fatal: false,
    });
  }
}
