import {
  ChangeDetectionStrategy,
  Component,
  inject,
  OnInit,
  Pipe,
  PipeTransform,
  Signal,
} from '@angular/core';
import { CrmLayoutV2FragmentDirective } from 'common-module/layout-v2';
import { NzDropDownModule } from 'ng-zorro-antd/dropdown';
import { NzIconModule } from 'ng-zorro-antd/icon';

import { Organization } from '~/api/organization/organization.service';

import { LayoutOrganizationProvider } from './layout-organization.provider';

@Pipe({ standalone: true, name: 'organizationName' })
export class OrganizationNamePipe implements PipeTransform {
  transform(
    value: string | null,
    organizations: Organization[] | undefined,
  ): string {
    if (!value) {
      return 'N/A';
    }

    return organizations?.find((org) => org.id === value)?.name ?? 'N/A';
  }
}

@Component({
  standalone: true,
  selector: 'app-layout-organization',
  templateUrl: 'layout-organization.component.html',
  styleUrls: ['layout-organization.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [NzIconModule, OrganizationNamePipe, NzDropDownModule],
})
export class LayoutOrganizationComponent
  extends CrmLayoutV2FragmentDirective<LayoutOrganizationProvider>
  implements OnInit
{
  override readonly provider = inject(LayoutOrganizationProvider);

  pickerSize!: Signal<'compact' | 'default'>;
  visible = false;

  ngOnInit() {
    this.pickerSize = this.provider.pickerSize$({
      siderControl: this.siderControl,
    });
  }

  setOrganization(organization: Organization) {
    this.visible = false;
    this.provider.setOrganization(organization.id);
  }
}
