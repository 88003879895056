import { CrmAuthConfig, CrmAuthRouteKey } from 'common-module/auth';

export const authConfig: CrmAuthConfig = {
  components: {
    login: {
      showForgottenPassword: false,
    },
    wrapper: {
      imagePosition: 'left',
      imageSrc: '/assets/images/auth_image.png',
      layout: 'image',
      logoSrc: '/assets/images/default-logo-black.svg',
    },
  },
  routeGuard: {
    disabledRoutes: [
      CrmAuthRouteKey.forgottenPassword,
      CrmAuthRouteKey.resetPassword,
    ],
  },
  interceptor: {
    interceptHttpCode: [401],
  },
};
