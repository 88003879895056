import { inject, Injectable, signal } from '@angular/core';
import { CrmDictionary, CrmResolvable } from 'common-module/core/types';
import { CrmTranslateService } from 'common-module/translate';
import { EMPTY, map, switchMap, takeUntil, tap } from 'rxjs';

import { JournalApiService } from '~/api/journal/journal-api.service';
import { UserJournalModel } from '~/api/journal/user/user-journal.model';
import { UserModel } from '~/api/user/model/user.model';
import { UserService } from '~/api/user/user.service';
import { parseDateTime } from '~/shared/utils/parse-date-time';

import {
  RightSidebarData,
  RightSidebarItemProvider,
} from '../right-sidebar.item';

import { Notification } from './notification';
import { NotificationsComponent } from './notifications.component';

@Injectable({ providedIn: 'root' })
export class NotificationsProvider extends RightSidebarItemProvider {
  notifications = signal<Notification[]>([]);
  isInitialized = signal(false);
  isLoadingNotifications = signal(false);

  private userService = inject(UserService);
  private journalService = inject(JournalApiService);
  private ts = inject(CrmTranslateService);

  private skip = 0;
  private limit = 10;
  private total: number | null = null;

  override init(): CrmResolvable<RightSidebarData> {
    return this.loadNext().pipe(
      tap(({ total }) => (this.total = total)),
      map(({ total }) => ({
        title: { message: 'notifications.title', context: { count: total } },
        render: { content: NotificationsComponent },
      })),
    );
  }

  override destroy() {
    this.skip = 0;
    this.total = null;
    this.notifications.set([]);
    this.isInitialized.set(false);
    this.isLoadingNotifications.set(false);
  }

  loadNext() {
    if (
      this.isLoadingNotifications() ||
      (this.total && this.skip >= this.total)
    ) {
      return EMPTY;
    }

    this.isLoadingNotifications.set(true);

    return this.loadData().pipe(
      tap(({ data }) => {
        this.skip += data.length;
        this.processData(data as UserJournalModel[]);
      }),
    );
  }

  private loadData() {
    return this.userService.user$.pipe(
      switchMap((user) =>
        this.journalService.list({
          '$sort[createdAt]': '-1',
          skip: this.skip,
          limit: this.limit,
          tags: 'timeline',
          '$or[0][$and][0][meta.action]': 'user_membership_added',
          '$or[0][$and][0][meta.added.ref]': user._id,
          '$or[1][$and][0][meta.action]': 'user_membership_deleted',
          '$or[1][$and][0][meta.deleted.ref]': user._id,
        }),
      ),
    );
  }

  private processData(data: UserJournalModel[]) {
    this.resolveUsers(data)
      .pipe(
        map((users) => this.mapData(data, users)),
        takeUntil(this.inputs.destroy$),
      )
      .subscribe((notifications) => {
        this.notifications.update((state) => [...state, ...notifications]);
        this.isLoadingNotifications.set(false);
        this.isInitialized.set(true);
      });
  }

  private resolveUsers(data: UserJournalModel[]) {
    const userIds = data.reduce<string[]>((result, journal) => {
      if (journal.jwt?.sub) {
        result.push(journal.jwt.sub);
      }

      result.push(journal.ref);

      return result;
    }, []);

    return this.userService.listUsersSafe(userIds);
  }

  private mapData(
    data: UserJournalModel[],
    users: UserModel[],
  ): Notification[] {
    return data.reduce<Notification[]>((result, journal) => {
      const author = users.find((_user) => _user._id === journal.jwt?.sub);
      const user = users.find((_user) => _user._id === journal.ref);

      result.push({
        id: journal._id,
        author: {
          id: author?._id as string,
          name: author?.displayName as string,
          image: undefined,
        },
        timestamp: parseDateTime(journal.createdAt),
        message: this.getMessage(
          journal,
          author?.displayName,
          user?.displayName,
        ),
      });

      return result;
    }, []);
  }

  private getMessage(
    journal: UserJournalModel,
    author?: string,
    user?: string,
  ) {
    const ctx: CrmDictionary = { author, user };

    switch (journal.meta?.action) {
      case 'user_membership_added':
        return this.ts.get('notifications.messages.assigned', ctx);
      case 'user_membership_deleted':
        return this.ts.get('notifications.messages.deleted', ctx);
      default:
        return '-';
    }
  }
}
