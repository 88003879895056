import {
  HttpContextToken,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { crmResolveExpression } from 'common-module/core';
import { CrmResolvable } from 'common-module/core/types';
import {
  combineLatest,
  filter,
  map,
  Observable,
  of,
  switchMap,
  take,
} from 'rxjs';

/**
 * Types of hooks, can be 'before' or 'after'
 *
 * 'before' hook is invoked before actual request with { req } as source for resolvable 'hook$'
 * 'after' hook is invoked after actual request with { req, response } as source for resolvable 'hook$'
 */
type Hook =
  | {
      type: 'before';
      hook$: CrmResolvable<Observable<unknown>, { req: HttpRequest<unknown> }>;
    }
  | {
      type: 'after';
      hook$: CrmResolvable<
        Observable<unknown>,
        { req: HttpRequest<unknown>; response: HttpResponse<unknown> }
      >;
    };

/**
 * Hook interceptor context token which stores array of context
 *
 * @usage - in params for http request
 *
 * options: {
 *   context: new HttpContext().set(HOOK_INTERCEPTOR_CONTEXT, [
 *     {
 *       type: 'before',
 *       hook$: of(1).pipe(tap(() => console.log('hook 1'))),
 *     },
 *     {
 *       type: 'before',
 *       hook$: ({ req }) => of(1).pipe(tap(() => console.log('hook 2', { req }))),
 *     },
 *     {
 *       type: 'after',
 *       hook$: ({ req, response }) => of(1).pipe(tap(() => console.log('hook 3', { req, response }))),
 *     },
 *     {
 *       type: 'after',
 *       hook$: of(1).pipe(tap(() => console.log('hook 4'))),
 *     },
 *   ]),
 * }
 *
 * @output
 *
 * hook 1
 * hook 2 { req: _HttpRequest }
 * // here actual request is invoked
 * hook 3 { req: _HttpRequest, response: _HttpResponse }
 * hook 4
 */
export const HOOK_INTERCEPTOR_CONTEXT = new HttpContextToken<Hook[]>(() => []);

/**
 * Interceptor which performs registered hooks for specific request
 */
@Injectable()
export class HookInterceptor implements HttpInterceptor {
  intercept(
    req: HttpRequest<unknown>,
    next: HttpHandler,
  ): Observable<HttpEvent<unknown>> {
    const context = req.context.get(HOOK_INTERCEPTOR_CONTEXT);

    if (context.length === 0) {
      return next.handle(req);
    }

    let beforeHooks = context
      .filter(({ type }) => type === 'before')
      .map(({ hook$ }) =>
        crmResolveExpression({ resolvable: hook$, source: { req } }),
      );

    if (beforeHooks.length === 0) {
      beforeHooks = [of(null)];
    }

    return combineLatest(beforeHooks).pipe(
      take(1),
      switchMap(() =>
        next.handle(req).pipe(
          filter((resp) => resp instanceof HttpResponse),
          switchMap((response) => {
            let afterHooks = context
              .filter(({ type }) => type === 'after')
              .map(({ hook$ }) =>
                crmResolveExpression({
                  resolvable: hook$,
                  source: { req, response },
                }),
              );

            if (afterHooks.length === 0) {
              afterHooks = [of(null)];
            }

            return combineLatest(afterHooks).pipe(
              take(1),
              map(() => response),
            );
          }),
        ),
      ),
    );
  }
}
