<div class="layout-organization layout-organization-{{ pickerSize() }}">
  <div
    class="layout-organization-picker"
    nz-dropdown
    [nzDropdownMenu]="menu"
    nzTrigger="click"
    [(nzVisible)]="visible"
    #pickerEl
  >
    <div class="logo">
      <img src="/assets/images/small-logo.svg" alt="logo" />
    </div>
    <div class="title">
      {{
        provider.currentOrganization()
          | organizationName: provider.organizations()
      }}
    </div>
    <div class="collapse">
      <i nz-icon nzType="icons:down-line"></i>
    </div>
  </div>
</div>

<nz-dropdown-menu #menu="nzDropdownMenu">
  <ul
    class="layout-organization-list"
    [style.width.px]="pickerSize() === 'default' ? pickerEl.offsetWidth : null"
  >
    @for (organization of provider.organizations(); track organization.id) {
      <li
        class="layout-organization-list-item"
        (click)="setOrganization(organization)"
      >
        {{ organization.name }}
      </li>
    }
  </ul>
</nz-dropdown-menu>
