import { CrmEndpointModuleConfig } from 'common-module/endpoint';

import { environment } from '~/environments/environment';

export const endpointConfig: CrmEndpointModuleConfig = {
  endpoints: [
    { name: 'crm', baseUrl: environment.nodeUrl },
    { name: 'assets', baseUrl: 'assets' },
  ],
};
