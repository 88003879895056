import {
  ChangeDetectionStrategy,
  Component,
  HostBinding,
  inject,
  OnDestroy,
  signal,
} from '@angular/core';
import { CrmLoadingComponent } from 'common-module/common';
import { CrmLayoutV2FragmentDirective } from 'common-module/layout-v2';
import type { CrmLayoutSiderControl } from 'common-module/layout-v2/helpers/layout-sider-control';
import { CrmRendererDirective } from 'common-module/renderer';
import { CrmTranslatePipe } from 'common-module/translate';
import { NzBadgeComponent } from 'ng-zorro-antd/badge';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzSpinModule } from 'ng-zorro-antd/spin';

import { TitleComponent } from '../../../components/typography/title.component';

import { getRightSidebarItems } from './get-right-sidebar-items';
import { RightSidebarItem } from './right-sidebar.item';
import { RightSidebarProvider } from './right-sidebar.provider';

@Component({
  standalone: true,
  selector: 'app-right-sidebar',
  templateUrl: 'right-sidebar.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    NzIconModule,
    CrmTranslatePipe,
    CrmRendererDirective,
    NzSpinModule,
    CrmLoadingComponent,
    NzBadgeComponent,
    TitleComponent,
  ],
})
export class RightSidebarComponent
  extends CrmLayoutV2FragmentDirective<RightSidebarProvider>
  implements OnDestroy
{
  @HostBinding('class') protected readonly hostClass = 'ehr-action-sidebar';

  override readonly provider = inject(RightSidebarProvider);

  override siderControl!: CrmLayoutSiderControl;

  selectedItem = signal<RightSidebarItem | undefined>(undefined);

  protected items = getRightSidebarItems();

  override ngOnDestroy() {
    super.ngOnDestroy();

    this.destroyCurrentItem();
    this.siderControl.close();
  }

  protected selectItem(item: RightSidebarItem) {
    if (this.selectedItem()?.key === item.key) {
      this.close();
      return;
    }

    this.siderControl.open();
    this.destroyCurrentItem();
    this.selectedItem.set(item);
    item.init();
  }

  protected close() {
    this.destroyCurrentItem();
    this.selectedItem.set(undefined);
    this.siderControl.close();
  }

  private destroyCurrentItem() {
    this.selectedItem()?.destroy();
  }
}
