import { inject, Injectable } from '@angular/core';
import { CanMatchFn, Route, UrlSegment } from '@angular/router';
import { CrmMessageService } from 'common-module/message';
import { catchError, of, switchMap, take, throwError } from 'rxjs';

import { OrganizationService } from '~/api/organization/organization.service';

@Injectable({ providedIn: 'root' })
export class OrganizationGuard {
  private organization = inject(OrganizationService);
  private message = inject(CrmMessageService);

  /**
   * Function which matches organization route.
   */
  canMatch(_: Route, segments: UrlSegment[]) {
    return this.checkSegmentOrganization(segments[0].path).pipe(
      catchError(() => of(false)),
    );
  }

  /**
   * Check first segment is allowed organization for logged user
   * If segment is allowed organization, set current organization with segment and return true
   * Otherwise remove saved organization if it's same as segment and throw error
   *
   * @param segment
   * @private
   */
  private checkSegmentOrganization(segment: string) {
    return this.organization.organizations$.pipe(
      take(1),
      switchMap((organizations) => {
        const isAllowedOrganization = organizations.some(
          ({ id }) => id === segment,
        );

        if (isAllowedOrganization) {
          this.organization.setCurrentOrganization(segment);
          return of(true);
        }

        this.removeSavedOrganizationIfSame(segment);
        this.message.error(
          'You are trying to access organization you are not assigned to.',
        );
        return throwError(() => new Error('Unknown organization'));
      }),
    );
  }

  /**
   * Remove saved organization if it's same as segment
   *
   * @param segment
   * @private
   */
  private removeSavedOrganizationIfSame(segment: string) {
    const currentOrganization =
      this.organization.loadCurrentOrganizationFromStorage();

    if (currentOrganization === segment) {
      this.organization.removeSavedOrganization();
    }
  }
}

/**
 * Organization guard factory function
 */
export const organizationGuardFn = (): CanMatchFn => (route, segments) =>
  inject(OrganizationGuard).canMatch(route, segments);
