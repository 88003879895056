import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { CrmMessageService } from 'common-module/message';
import { catchError, Observable, throwError } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class ErrorInterceptor implements HttpInterceptor {
  readonly messageService = inject(CrmMessageService);

  intercept(
    req: HttpRequest<unknown>,
    next: HttpHandler,
  ): Observable<HttpEvent<unknown>> {
    return next.handle(req).pipe(
      catchError((err: HttpErrorResponse) => {
        if (req.method === 'HEAD') {
          return throwError(() => err);
        }

        switch (err.status) {
          case 400:
          case 404:
            this.handleRequestMessage(err);
            break;
          case 403:
            this.messageService.error('error.forbiddenAction');
            break;
          case 500:
            this.messageService.error('error.somethingWentWrong');
            break;
        }

        return throwError(() => err);
      }),
    );
  }

  private handleRequestMessage(err: HttpErrorResponse) {
    this.messageService.error(this.getRequestErrorMessage(err));
  }

  private getRequestErrorMessage(err: HttpErrorResponse) {
    if (!err.error?.message) {
      return err.message;
    }

    if (Array.isArray(err.error.message)) {
      return err.error.message.join(', ');
    }

    return err.error.message;
  }
}
