import { Injector } from '@angular/core';

import { RightSidebarItem } from '../right-sidebar.item';

import { NotificationsProvider } from './notifications.provider';

export class NotificationsItem extends RightSidebarItem {
  override key = 'notifications';
  override icon = 'icons:notification-line';
  override selectedIcon = 'icons:notification-fill';

  protected override initProvider(injector: Injector): NotificationsProvider {
    return injector.get(NotificationsProvider);
  }
}
