import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { catchError, Observable, throwError } from 'rxjs';

import { JwtService } from '../services/jwt.service';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
  protected jwtService = inject(JwtService);

  intercept(
    req: HttpRequest<unknown>,
    next: HttpHandler,
  ): Observable<HttpEvent<unknown>> {
    const token = this.jwtService.token;

    if (!token) {
      return next.handle(req);
    }

    return next
      .handle(
        req.clone({
          ...req,
          headers: req.headers.append(
            'Authorization',
            ['Bearer', token].join(' '),
          ),
        }),
      )
      .pipe(catchError((err) => throwError(() => err)));
  }
}
