import { Injector } from '@angular/core';

import { RightSidebarItem } from '../right-sidebar.item';

import { TodosProvider } from './todos.provider';

export class TodosItem extends RightSidebarItem {
  override key = 'todos';
  override icon = 'icons:list-check-2-line';
  override selectedIcon = 'icons:list-check-2-fill';

  protected override initProvider(injector: Injector): TodosProvider {
    return injector.get(TodosProvider);
  }
}
