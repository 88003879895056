import { inject, Injectable } from '@angular/core';
import { CrmLayoutMenuProvider } from 'common-module/layout-v2';
import { map } from 'rxjs';

import { OrganizationService } from '~/api/organization/organization.service';

/**
 * Override layout menu provider from common-module.
 *
 * We need to dynamically change first segment of url (prefix) according to selected organization.
 */
@Injectable({ providedIn: 'root' })
export class LayoutMenuProvider extends CrmLayoutMenuProvider {
  private organization = inject(OrganizationService);

  override getComponentConfig() {
    return this.organization.currentOrganization$.pipe(
      map((organization) => ({ ...this.config, prefix: organization })),
    );
  }
}
