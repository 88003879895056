import { BreakpointObserver } from '@angular/cdk/layout';
import { Injectable } from '@angular/core';
import { CrmLayoutV2Provider } from 'common-module/layout-v2';

@Injectable({ providedIn: 'root' })
export class LayoutProvider extends CrmLayoutV2Provider {
  constructor(observer: BreakpointObserver) {
    super();

    observer.observe('(max-width: 1600px)').subscribe(({ matches }) => {
      if (matches) {
        this.siderLeftControl.close();
      } else {
        this.siderLeftControl.open();
      }
    });
  }
}
