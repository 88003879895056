import { CrmLayoutV2Config } from 'common-module/core/types';
import {
  CrmLayoutCollapseComponent,
  CrmLayoutLogoComponent,
  CrmLayoutMenuComponent,
  CrmLayoutProfileComponent,
  CrmLayoutProfileItemKey,
  crmLayoutSidebarOnly,
} from 'common-module/layout-v2';

import { RightSidebarComponent } from '~/shared/crm/layout/right-sidebar/right-sidebar.component';
import { LayoutOrganizationComponent } from '~/shared/crm/layout/organization/layout-organization.component';
import { LayoutMenuProvider } from '~/shared/crm/layout/menu/layout-menu.provider';
import { LayoutProfileProvider } from '~/shared/crm/layout/profile/layout-profile.provider';

import { menuConfig } from './menu.config';

export const layoutConfig: CrmLayoutV2Config = {
  siderLeft: {
    ...crmLayoutSidebarOnly.siderLeft,
    theme: 'dark',
    collapseBreakpoint: undefined,
    fragments: [
      { key: 'collapse', content: CrmLayoutCollapseComponent },
      { key: 'logo', content: CrmLayoutLogoComponent },
      { key: 'organization', content: LayoutOrganizationComponent },
      {
        key: 'menu',
        content: CrmLayoutMenuComponent,
        provider: LayoutMenuProvider,
      },
      {
        key: 'profile',
        content: CrmLayoutProfileComponent,
        provider: LayoutProfileProvider,
      },
    ],
  },
  siderRight: {
    collapsedWidth: 64,
    width: '441px',
    defaultCollapsed: true,
    fragments: [{ key: 'right-sidebar', content: RightSidebarComponent }],
  },
  menu: menuConfig,
  collapse: {
    icon: 'icons:layout-left-line',
    iconCollapsed: 'icons:layout-left-line',
  },
  profile: {
    dropdownTrigger: 'click',
    dropdownPlacement: 'right',
    dropdownIcon: 'icons:down-line',
    dropdownIconVisible: 'icons:right-line',
    avatarConfig: {
      icon: 'icons:user-1-line',
      size: {
        base: 'default',
        compact: 'default',
      },
    },
    items: [
      {
        key: CrmLayoutProfileItemKey.LOGOUT,
        title: 'crm.layout.profile.items.logout',
        icon: 'icons:power-line',
      },
    ],
  },
};
