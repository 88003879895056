import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { catchError, Observable, switchMap, take, throwError } from 'rxjs';

import { OMIT_ORGANIZATION_HEADER } from './organization.context';
import { OrganizationService } from './organization.service';

@Injectable({ providedIn: 'root' })
export class OrganizationInterceptor implements HttpInterceptor {
  readonly organizationService = inject(OrganizationService);

  intercept(
    req: HttpRequest<unknown>,
    next: HttpHandler,
  ): Observable<HttpEvent<unknown>> {
    if (req.context.get(OMIT_ORGANIZATION_HEADER)) {
      return next.handle(req);
    }

    const organization$ = this.organizationService.currentOrganization$;

    if (!organization$) {
      return next.handle(req);
    }

    return organization$.pipe(
      take(1),
      switchMap((organization) => {
        let headers = req.headers;
        if (organization) {
          headers = headers.append('x-organization', organization);
        }

        return next.handle(req.clone({ ...req, headers }));
      }),
      catchError((err) => throwError(() => err)),
    );
  }
}
