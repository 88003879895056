import { IGoogleAnalyticsCommand } from 'ngx-google-analytics/lib/interfaces/i-google-analytics-command';

import { environment } from '~/environments/environment';

export const gaInitCommand: IGoogleAnalyticsCommand = {
  command: 'config',
  values: [
    environment.ga,
    {
      send_page_view: false,
      environment: environment.env,
      app_name: environment.projectName,
      app_version: environment.version,
    },
  ],
};
