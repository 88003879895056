import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { AvatarComponent } from '~/shared/components/avatar/avatar.component';
import { FormatDateTimePipe } from '~/shared/pipes/format-date-time.pipe';

import { Notification } from './notification';

@Component({
  standalone: true,
  selector: 'app-notification',
  template: `
    <div class="header">
      <div class="author centered">
        <app-avatar [src]="notification.author.image" size="small"></app-avatar>
        <span class="ml-1">{{ notification.author.name }}</span>
      </div>
      <div class="timestamp">
        {{ notification.timestamp | formatDateTime }}
      </div>
    </div>
    <div class="message">{{ notification.message }}</div>
  `,
  styles: [
    `
      :host {
        display: block;
        padding: 16px;
        border-radius: 8px;
        color: #595959;
      }

      .header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 16px;
        font-size: 12px;
      }
    `,
  ],
  imports: [FormatDateTimePipe, AvatarComponent],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NotificationComponent {
  @Input() notification!: Notification;
}
