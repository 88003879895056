import { inject } from '@angular/core';
import { Routes } from '@angular/router';

import { environment } from '~/environments/environment';
import { ReportsGuard } from '~/features/reports/reports.guard';
import { appGuardFn } from '~/shared/guards/app.guard';
import { organizationGuardFn } from '~/shared/guards/organization.guard';

export const appRoutes: Routes = [
  {
    path: 'auth',
    loadChildren: () => import('common-module/auth').then((a) => a.authRoutes),
  },
  {
    path: 'token',
    loadChildren: () => import('./features/token/routes'),
  },
  {
    path: '',
    loadChildren: () => organizationRoutes,
    canMatch: [appGuardFn()],
  },
  {
    path: '**',
    redirectTo: 'auth',
  },
];

export const organizationRoutes: Routes = [
  {
    path: ':organization',
    canMatch: [organizationGuardFn()],
    loadComponent: () =>
      import('common-module/layout-v2').then(
        (m) => m.CrmLayoutV2OutletComponent,
      ),
    children: [
      {
        path: 'dashboard',
        loadChildren: () => import('./features/dashboard/routes'),
        title: 'dashboard.title',
      },
      {
        path: 'forms',
        loadChildren: () => import('./features/forms/routes'),
      },
      {
        path: 'forms/v2',
        loadChildren: () => import('./features/forms2/routes'),
      },
      {
        path: 'users',
        loadChildren: () => import('./features/users/routes'),
        title: 'layout.menu.users',
      },
      {
        path: 'events',
        loadChildren: () => import('./features/events/routes'),
        title: 'events.title',
        canMatch: [() => environment.featureFlags.events.enabled],
      },
      {
        path: 'reports',
        loadChildren: () => import('./features/reports/routes'),
        canMatch: [() => inject(ReportsGuard).canMatch()],
        title: 'layout.menu.reports',
      },
      {
        path: '**',
        redirectTo: 'users',
      },
    ],
  },
  {
    path: '**',
    redirectTo: '/auth',
  },
];
