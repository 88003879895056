import {
  ChangeDetectionStrategy,
  Component,
  inject,
  OnInit,
  TemplateRef,
  viewChild,
} from '@angular/core';
import { CrmAppComponent } from 'common-module/core';
import { CrmVersionDetectorService } from 'common-module/version';
import { filter, take } from 'rxjs';
import { NzButtonComponent } from 'ng-zorro-antd/button';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { CrmTranslatePipe, CrmTranslateService } from 'common-module/translate';

import { TextComponent } from '~/shared/components/typography/text.component';
import { TitleComponent } from '~/shared/components/typography/title.component';

@Component({
  standalone: true,
  selector: 'app-root',
  template: `
    <ng-template #updateTmpl>
      <app-text message="reloadApp.message" margin="1"></app-text>
      <button nz-button nzBlock nzType="primary" (click)="version.reloadApp()">
        {{ 'reloadApp.action' | crmTranslate }}
      </button>
    </ng-template>

    <crm-app></crm-app>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    CrmAppComponent,
    TextComponent,
    TitleComponent,
    NzButtonComponent,
    CrmTranslatePipe,
  ],
})
export class AppComponent implements OnInit {
  protected tmpl = viewChild('updateTmpl', { read: TemplateRef });
  protected version = inject(CrmVersionDetectorService);
  private notification = inject(NzNotificationService);
  private translate = inject(CrmTranslateService);

  protected available$ = this.version.appUpdateAvailable$.pipe(
    filter((available) => available),
    take(1),
  );

  ngOnInit() {
    this.available$.subscribe(() => {
      const tmpl = this.tmpl();

      if (!tmpl) {
        return;
      }

      this.notification.blank(this.translate.get('reloadApp.title'), tmpl, {
        nzDuration: 0,
        nzClass: 'ehr-reload-app-notification',
      });

      const notificationCloseElement = document.querySelector(
        '.ehr-reload-app-notification .ant-notification-notice-close',
      );

      notificationCloseElement?.remove();
    });

    this.version.init();
  }
}
