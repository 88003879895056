import { inject, Injector } from '@angular/core';

import { NotificationsItem } from './notifications/notifications.item';
import { RightSidebarItem } from './right-sidebar.item';
import { TodosItem } from './todos/todos.item';

export const getRightSidebarItems = (): RightSidebarItem[] => {
  const injector = inject(Injector);

  return [new NotificationsItem(injector), new TodosItem(injector)];
};
