import { inject, Injectable } from '@angular/core';
import {
  CrmLayoutProfileProvider,
  CrmLayoutSiderControl,
} from 'common-module/layout-v2';
import { crmGetUserName } from 'common-module/user';
import { CrmUserProfile } from 'common-module/user-profile';
import { map, Observable } from 'rxjs';

import { UserModel } from '~/api/user/model/user.model';

import { MediaService } from '../../../services/media.service';

@Injectable({ providedIn: 'root' })
export class LayoutProfileProvider extends CrmLayoutProfileProvider {
  private readonly mediaService = inject(MediaService);

  override profile$(_options: {
    siderControl?: CrmLayoutSiderControl;
  }): Observable<CrmUserProfile> {
    return this.userService.user$.pipe(
      map<UserModel, CrmUserProfile>((user) => ({
        name: crmGetUserName(user),
        role: this.getRole(user.roles),
        avatar: this.mediaService.resolveUserAvatarUrl(user._id),
      })),
    );
  }
}
