import { inject, Injectable } from '@angular/core';
import { CrmMessageService } from 'common-module/message';
import { switchMap, tap } from 'rxjs';

import { UserService } from '~/api/user/user.service';
import { OrganizationService } from '~/api/organization/organization.service';

@Injectable({ providedIn: 'root' })
export class ReportsGuard {
  protected userService = inject(UserService);
  protected organizationService = inject(OrganizationService);
  protected messageService = inject(CrmMessageService);

  canMatch() {
    return this.userService.user$.pipe(
      switchMap((user) => this.organizationService.isOrganizationAdmin(user)),
      tap((isAdmin) => this.showMessage(isAdmin)),
    );
  }

  private showMessage(isAdmin: boolean) {
    if (isAdmin) {
      return;
    }

    this.messageService.error('features.reports.lackOfPermissions');
  }
}
