import { inject, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, DefaultTitleStrategy } from '@angular/router';
import { CrmTranslateService } from 'common-module/translate';

import { APP_TITLE } from '~/environments/variables';

@Injectable({ providedIn: 'root' })
export class TitleService extends DefaultTitleStrategy {
  private readonly translateService = inject(CrmTranslateService);

  override getResolvedTitleForRoute(snapshot: ActivatedRouteSnapshot) {
    const title = super.getResolvedTitleForRoute(snapshot);

    if (!title) {
      return APP_TITLE;
    }

    return [this.translateService.get(title), APP_TITLE].join(' | ');
  }
}
